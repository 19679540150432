/* Fonts
-------------------------------------------------- */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
@font-face { font-family: Proxima-Nova; src: url('./components/fonts/Proxima Nova/Proxima Nova Reg.otf'); }

html{
  scroll-behavior: smooth;
}
body {

  color: #5a5a5a;
  overflow-x: hidden;
  font-family: 'Playfair Display', serif !important;
}
a:hover {
  text-decoration: none !important;
}

/* header.js
-------------------------------------------------- */
header {
  height: 15rem;
}

.navbar {
  z-index: 99;
}

.navbar-nav {
  width: 100%;
  text-align: center;
  font-family: 'Playfair Display', serif;

}

.nav-link {
  font-size: 19px;
  float: none;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 6px !important;
  font-weight: 300;
  cursor: pointer;
}

.navbar-nav a:hover{
  color: #00B3B9 !important;
}

nav {
  background-image: linear-gradient(#ffffff, #bfe7e8);
  border-bottom: solid #00B3B9;
  display: block !important;
  -webkit-transition: 2s !important; /* Safari prior 6.1 */
  transition: 2s !important;

}

.logo {
  width: 8.5%;
  display: block;
  margin: auto;
}

.row-h {
  position: absolute;
}
.row-menu {
  position: relative;
  left: -50%;
  text-align: left;
  top: 100px;
  z-index: 99;
}

.collapse ul {
  list-style: none;
  position: relative;
  left: 50%;
}

@media screen and (max-width: 766px) {
  .logo {
    width: 22%;
  }
}

/* herobanner.js
-------------------------------------------------- */

.heroTitle {
  font-size: 60px;
  letter-spacing: 2px;
  color: #6B7A8F;
  font-family: 'Playfair Display', serif;
  text-align: center;
}

.heroLink {
  color: #00b7bd;
  font-family: 'Playfair Display', serif;
  text-align: center;
}

.heroLink:hover{
  margin-left:7px;
  font-weight:900;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
  text-decoration: none;
  box-shadow: gray -4px 6px 9px 0px;
}

.heroImage {
  width: 60%;
}

.left-img {
  float: left;
}

.right-img {
  float: right;
}

.row {
  align-items: flex-start;
  justify-content: center;
}
.row h2 {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-weight: 100;
  color: #00B3B9;
}

.row h3{
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 1.35em;
  font-style: italic;
  color: #6B7A8F;
}

.subs{
  color: #6B7A8F;
  font-family: 'Playfair Display', serif;
  text-align: center;
}

.list-group-item {
  color: #6B7A8F;
  font-family: 'Playfair Display', serif;
  font-size: 18px;
}

.faders{
  border: none !important;
}
@media screen and (max-width: 992px) {
  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .heroImage {
    width: 120%;
  }

  .heroTitle {
    font-size: 240%;
  }

  .row{
    margin-bottom: 50%;
  }

  .sub2{
    width: 220%;
  }

  .heroSubLink {
    width: 220%
  }

  .list-group-flush{
    width: 200%;
    margin-left: -100%;
    margin-top: 30%;
  }


}

/* Comparision Table
-------------------------------------------------- */

ul {
  list-style: none;
  padding: 0px;
}

.pricing-2{
  font-family: 'Playfair Display', serif;
  color:#6B7A8F;
}
.pricing-tables .col-md-3:first-child .pricing-table {
  border-radius: 25px 0px 0px 25px;
}

.pricing-tables .col-md-3:last-child .pricing-table {
  border-radius: 0px 25px 25px 0px;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
}

.pricing-table {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding-bottom: 40px;
}

.pricing-table .price {
  padding: 40px 0px;
  font-weight: 600;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.pricing-table .price .sub {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.3);
  position: relative;
  bottom: 10px;
}

.pricing-table .price .amount {
  color: #fff;
  font-size: 56px;
  display: inline-block;
  padding: 0px 8px;
}

.pricing-table .features {
  margin: 40px 0px;
}

.pricing-table .features li {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.pricing-table .features li:last-child {
  margin-bottom: 0px;
}

.pricing-table .features li strong {
  font-weight: 600;
}

.pricing-table.emphasis {
  background-color: #e74c3c;
}

.pricing-2 .pricing-tables .col-md-3:first-child .pricing-table {
  border-radius: 25px 0px 0px 25px;
}

.pricing-2 .pricing-tables .col-md-3:last-child .pricing-table {
  border-radius: 0px 25px 25px 0px;
  border-right: 2px solid rgba(35, 35, 35, 0.2);
}

.pricing-2 .pricing-table {
  border-top: 2px solid rgba(35, 35, 35, 0.2);
  border-bottom: 2px solid rgba(35, 35, 35, 0.2);
  border-left: 2px solid rgba(35, 35, 35, 0.2);
  text-align: center;
  padding-bottom: 40px;
}

.pricing-2 .pricing-table .features {
  margin: 0px;
}

.pricing-2 .pricing-table .features li:first-child {
  border-top: none;
}

.pricing-2 .pricing-table .features li {
  color: #6B7A8F;
  border-top: 2px solid rgba(35, 35, 35, 0.2);
  padding: 24px 0px;
  margin: 0;
}

.pricing-2 .pricing-table .price {
  border-top: 2px solid rgba(35, 35, 35, 0.2);
  padding-bottom: 24px;
  border-bottom: none;
}

.pricing-2 .pricing-table .price .amount {
  color: #333333;
}

.pricing-2 .pricing-table .price .sub {
  color: #777777;
  opacity: 0.7;
}

.pricing-2 .pricing-table.emphasis {
  background-color: #2c3e50;
}

.pricing-2 .pricing-table.emphasis .features li {
  color: #fff;
  background-color: #2c3e50 !important;
}

.pricing-2 .pricing-table.emphasis .price .amount,
.pricing-2 .pricing-table.emphasis .sub {
  color: #fff;
}

.pricing-2 .feature-list {
  padding-bottom: 0px;
}

.pricing-2 .pricing-table .features li:nth-child(even) {
  background: #b6d5d440;
}

.pricing-tables .no-pad {
  padding: 0px 15px;
}

.pricing-tables .no-pad-left {
  padding-left: 15px;
}

.pricing-tables .no-pad-right {
  padding-right: 15px;
}

.pricing-table {
  margin-bottom: 16px;
  border-radius: 25px !important;
  border: 2px solid rgba(255, 255, 255, 0.2) !important;
}

.pricing-2 .hidden-sm:first-child {
  display: none;
}

.pricing-2 .pricing-table.emphasis .features li {
  border-radius: 25px;
}

.pricing-2 .pricing-table .features li:first-child {
  font-size: 24px;
}

.pricing-tables .no-pad {
  padding: 0px 15px;
}

.pricing-tables .no-pad-left {
  padding-left: 15px;
}

.pricing-tables .no-pad-right {
  padding-right: 15px;
}

.pricing-table {
  margin-bottom: 30px;
  border-radius: 25px !important;
  border: 2px solid rgba(255, 255, 255, 0.2) !important;
}

/* FOOTER
-------------------------------------------------- */

/* Footer */

section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: #6B7A8F;
}
#footer {
  background-image: linear-gradient(#00b3b924, #ffffff);
  font-family: 'Proxima-Nova', sans-serif;
}
#footer h5{
  padding-left: 10px;
  border-left: 3px solid #B6D5D4;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color:#6B7A8F;
}
#footer a {
  color: #6B7A8F;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
  padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
  font-size:25px;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}
#footer ul.social li:hover a i {
  font-size:30px;
  margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
  color:#6B7A8F;
}
#footer ul.social li a:hover{
  color:#6B7A8F;
}
#footer ul.quick-links li{
  padding: 3px 0;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}
#footer ul.quick-links li:hover{
  padding: 3px 0;
  margin-left:5px;
  font-weight:700;
}
#footer ul.quick-links li a i{
  margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

.newsletter-box input#appendedInputButton {
  background: #FFFFFF;
  display: inline-block;
  float: left;
  height: 30px;
  clear: both;
  width: 100%;
}
.newsletter-box .btn {
  border: medium none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  height: 40px;
  padding: 0;
  width: 100%;
  color: #fff;
}
.newsletter-box {
  overflow: hidden;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
}
.inp .label {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
}
.inp .border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #B6D5D4;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
}
.inp input:hover {
  background: rgba(34,50,84,0.03);
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-26px) scale(0.75);
}
.inp input:focus {
  background: none;
  outline: none;
}
.inp input:focus + span {
  color: #B6D5D4;
  transform: translateY(-26px) scale(0.75);
}
.inp input:focus + span + .border {
  transform: scaleX(1);
}

.copy{
  color: #6B7A8F;
}

#email-submit{
  position: absolute;
  left: 77%;
  top: 17%;
  -webkit-box-align: center;
  align-items: center;
  background-color: #B6D5D4;
  display: flex;
  height: 2.5rem;
  width: 5rem;
  -webkit-box-pack: center;
  justify-content: center;
  /* position: relative; */
  transition-duration: 0.5s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;
  flex: 0 1 auto;
  overflow: hidden;
  font-family: 'Avenir';
  font-size: 16px;
  color: #6B7A8F;
}

#form-table {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

#form-table-covid {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

#form-submit {
  -webkit-box-align: center;
  align-items: center;
  background-color: #B6D5D4;
  height: 2.5rem;
  width: 5rem;
  -webkit-box-pack: center;
  justify-content: center;
  /* position: relative; */
  transition-duration: 0.5s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;
  flex: 0 1 auto;
  overflow: hidden;
  font-family: 'Avenir';
  font-size: 16px;
  color: #6B7A8F;
}

#form-no {
  -webkit-box-align: center;
  align-items: center;
  background-color: #e0e0e0;
  height: 2.5rem;
  width: 5rem;
  -webkit-box-pack: center;
  justify-content: center;
  /* position: relative; */
  transition-duration: 0.5s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;
  flex: 0 1 auto;
  overflow: hidden;
  font-family: 'Avenir';
  font-size: 16px;
  color: #6B7A8F;
}

@media (max-width:767px){
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

/* Visit Us - Contact
-------------------------------------------------- */

@media (max-width:767px){
  .iframeMOb{
    width: 325px;
    height: 400px;
  }
}

/* Center
-------------------------------------------------- */
.gallery img {
  width: 100%;
}

/* Team
-------------------------------------------------- */
.card-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.acc {
  width:300px;
}

.card-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn {
  width: 100%;
}

.card-body {
  text-align: center;
  color: #6B7A8F;
  font-family: 'Playfair Display', serif;
}

.btn-link{
  color: #00B3B9 !important;
}

.btn-link:hover{
  text-decoration: none !important;
}

@media (max-width:767px){
  .card-div{
    margin-bottom: 50px !important;
  }

  .team-row{
    margin-bottom: 0 !important;
  }
}


/* Services
------------------------------------------------- */

.service-block {
  border: solid #39b7bd;;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 5%;
}

.serviceRowB{
  display: flex;
  margin-bottom: 15%;
}

@media screen and (max-width: 992px) {

  .serviceRow {
    width: 50%;
    margin-bottom: 25%;
  }

  .serviceRowB {
    width: 100%;
    margin-bottom: 25%;
  }

  .service-block{
    margin-left: auto;
    margin-right: 2%;
  }

  .mobHead {
    width: 220%;
  }

}

/* Internal
------------------------------------------------- */

.patientInput {
  width: 500px;
  height: 35px;
}

.scheduleSelector {
  width: 80%;
  margin: 0 auto;
}
.capt{
  margin-left: 32%;
  margin-bottom: 5%;
}

@media screen and (max-width: 992px) {

  .capt{
    margin-left: 0;
  }
}

#submit-button{
   align-items: center;
   background-color: #B6D5D4;
   height: 2.5rem;
   margin: 10px;
   /* width: 5rem; */
   justify-content: center;
   /* position: relative; */
   /* transition-duration: 0.5s; */
   /* transition-property: background-color; */
   /* transition-timing-function: ease-in-out; */
   /* flex: 0 1 auto; */
   /* overflow: hidden; */
   font-family: 'Avenir';
   font-size: 16px;
   color: #6B7A8F;
 }

#former-sub{
  align-items: center;
  background-color: #B6D5D4;
  height: 4.5rem;
  width: 7rem;
  margin: 10px;
  /* width: 5rem; */
  justify-content: center;
  /* position: relative; */
  /* transition-duration: 0.5s; */
  /* transition-property: background-color; */
  /* transition-timing-function: ease-in-out; */
  /* flex: 0 1 auto; */
  /* overflow: hidden; */
  font-family: 'Avenir';
  font-size: 16px;
  color: #6B7A8F;    appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  /* margin: 0em; */
  /* font: 400 13.3333px Arial; */
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}

.react-datepicker-popper{
  left: -50px !important;
}

@media screen and (max-width: 992px) {
  .react-datepicker-popper{
    top: 18px !important;
  }

}

.MuiAppBar-root{
  height: auto;
}

.analyticBox{
  border: solid #B6D5D4;
  border-radius: 15px;
  background: #b6d5d43d;
  width: 50%;
  margin-left: auto;
  height: 90%;
  margin-right: auto;

}

#checkout-button {
  height: 36px;
  background: #556cd6;
  color: white;
  width: 20%;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0.6;
  border-radius: 0 0 6px 6px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
#checkout-button:hover {
  opacity: 0.8;
}

.email-group-item{
  font-family: "Playfair Display",serif;
  color: #6b7a8f;
  font-size: 18px;
  margin-left:15px;
}

.eventImg{
  width:48vh;
}

.thumbnail{
  position : absolute;
  top : 0;
  z-index : -5000;
  width : 0px;
  height : 0px;
}

.thumbnail img{
  object-fit : cover;
  width : 0px;
  height : 0px;
}

#dashboard-button{
  align-items: center;
  background-color: #82b9b8;
  height: 3.5rem;
  margin: 10px;
  /* width: 5rem; */
  justify-content: center;
  /* position: relative; */
  /* transition-duration: 0.5s; */
  /* transition-property: background-color; */
  /* transition-timing-function: ease-in-out; */
  /* flex: 0 1 auto; */
  /* overflow: hidden; */
  font-family: 'Avenir';
  font-size: 16px;
  color: #6B7A8F;
}